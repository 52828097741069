'use client'
import React from 'react'
import { navLinks } from '@/utils';

const NavLinks = () => {

  const handleScroll = (e, target, offset = 0) => {
    e.preventDefault();
    const element = document.querySelector(target);
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }; 
  

  return (
    <>
      {
        navLinks.map(item => (
          <button key={item.title} className='md:text-xs xl:text-base font-semibold rounded hover:scale-110 hover:text-primary transition-all duration-300' onClick={(e) => { handleScroll(e, item.scroll, item.offset) }}>{item.title}</button>
        ))
      }
    </>
  )
}

export default NavLinks