'use client'
import React from 'react'
import MobileDrawer from '@/app/components/MobileDrawer';
import Link from 'next/link';

export default function MenuButton() {

  return (
    <div className='flex md:hidden items-center gap-x-4'>
      <Link href='https://app.marinebroker.com.ar/auth/login' className='border-b-2 border-primary text-primary flex justify-center items-center md:text-sm xl:text-lg font-semibold hover:scale-110 transition-all duration-300'>Ingresar</Link>
      <MobileDrawer/>
    </div>
  )
}
