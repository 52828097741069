'use client'
import Image from 'next/image';
import { navLinks } from '@/utils';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet"

//This component renders a drawer for navigation and authentication of the app, only in the mobile version.
const MobileDrawer = () => {

  const handleScroll = (e, target, offset = 0) => {
    e.preventDefault();
    const element = document.querySelector(target);
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <Sheet>
      <SheetTrigger>
        <Image
          src={'/icons/menu.png'}
          alt='menu'
          width={25}
          className='h-auto w-7'
          height={100}
        />
      </SheetTrigger>
      <SheetContent side='left' className='flex flex-col justify-start items-center gap-y-4 py-20'>
        <SheetTitle className='w-full'>
          <nav className='h-auto w-full flex flex-col justify-start items-start gap-y-4'>
            {
              navLinks.map(item => (
                <div key={item.title} className='flex flex-col items-start w-full gap-y-2'>
                  <button type='button' onClick={(e) => { handleScroll(e, item.scroll, item.offset) }}>
                    <h4 className='text-base font-semibold'>{item.title}</h4>
                  </button>
                  <span className='w-full h-px bg-gray-400' ></span>
                </div>
              ))
            }
          </nav></SheetTitle>
        <SheetFooter className='bottom-6 absolute'>
          <Image
            src={'/marineBroker/logo.png'}
            width={120}
            height={100}
            alt='Logo marine brokers'
            className='w-32'
          />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}


export default MobileDrawer;